import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { UserAuth } from "../context/AuthContext";
import { db } from "../firebase";
import {
  FaCircle,
  FaArrowLeft,
  FaRegHeart,
  FaHeart,
  FaRegComment,
  FaUserCircle,
} from "react-icons/fa";
import { IoIosClose, IoIosHeartEmpty } from "react-icons/io";
import { CiChat1 } from "react-icons/ci";
import {
  IoArrowBackOutline,
  IoChatboxOutline,
  IoHeartOutline,
  IoNotifications,
  IoNotificationsOutline,
} from "react-icons/io5";
import {
  IoMdClose,
  IoIosNotifications,
  IoIosNotificationsOutline,
} from "react-icons/io";
import { IoMoonOutline, IoSunnyOutline } from "react-icons/io5";
import { BsHeart } from "react-icons/bs";
import { MdRefresh } from "react-icons/md";
import { MdDelete } from "react-icons/md";
import GoBack from "../components/GoBack";
import {
  collection,
  getDocs,
  setDoc,
  deleteDoc,
  arrayUnion,
  doc,
  where,
  query,
  serverTimestamp as firestoreTimestamp,
  updateDoc,
  onSnapshot,
  getDoc,
  addDoc,
  runTransaction,
  limit,
  setLogLevel,
  orderBy,
  startAfter,
  increment,
} from "firebase/firestore";
import ReactLoading from "react-loading";
import Navbar from "../components/Navbar";
import { RiArrowDropDownLine } from "react-icons/ri";
import ProfilePicture from "../components/ProfilePicture";

const DailyThoughts = () => {
  const { user, displayName, university, setTheCurrentPage } = UserAuth();
  const [isMobile, setIsMobile] = useState(false);
  const [postTitle, setPostTitle] = useState("");
  const [postText, setPostText] = useState("");
  const [postDATA, setPostDATA] = useState([]);
  const [openPostSomething, setOpenPostSomething] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [currentSort, setCurrentSort] = useState("Best");
  const [showModal, setShowModal] = useState(false);
  const sortingOptions = ["Best", "Hot", "New", "Top", "Rising"];

  const [isDarkMode, setIsDarkMode] = useState(false);
  const [isReminderModalOpen, setIsReminderModalOpen] = useState(true);

  const dtContainerRef = useRef(null);
  const textInputRef = useRef(null);
  const postTitleRef = useRef(null);
  const postMessageRef = useRef(null);

  const navigate = useNavigate();

  const handleSortSelect = (option) => {
    setCurrentSort(option);
    setShowModal(false);
  };

  useEffect(() => {
    if (isReminderModalOpen) {
      // Disable scrolling on the body when the modal is open
      document.body.style.overflow = "hidden";
    } else {
      // Re-enable scrolling when the modal is closed
      document.body.style.overflow = "auto";
    }

    // Cleanup when the component is unmounted or modal is closed
    return () => {
      document.body.style.overflow = "auto";
    };
  }, [isReminderModalOpen]);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 800);
    };

    handleResize();

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    const onBeforeUnload = (e) => {
      e.preventDefault();
      e.returnValue = "";
    };

    window.addEventListener("beforeunload", onBeforeUnload);
    return () => {
      window.removeEventListener("beforeunload", onBeforeUnload);
    };
  }, []);

  useEffect(() => {
    // Check if displayName and university are set
    if (!displayName || !university) {
      // Navigate to the main menu and replace the current entry in the history stack
      navigate("/", { replace: true });
    } else {
      setIsLoading(false);
    }
  }, [displayName, university, navigate]);

  const EachPost = ({ user, post, heartQty, commentQty }) => {
    const [isExpanded, setIsExpanded] = useState(false);
    const [isTruncated, setIsTruncated] = useState(false);
    const contentRef = useRef(null);

    const maxLines = 6; // Number of lines to show before truncating

    const handleToggleExpand = () => {
      setIsExpanded((prev) => !prev);
    };

    useEffect(() => {
      // Check if the text is truncated
      const checkIfTruncated = () => {
        if (contentRef.current) {
          const element = contentRef.current;
          setIsTruncated(element.scrollHeight > element.clientHeight);
        }
      };

      checkIfTruncated();
      // Re-check on resize or content change
      window.addEventListener("resize", checkIfTruncated);
      return () => window.removeEventListener("resize", checkIfTruncated);
    }, [post, isExpanded]);

    return (
      <div style={{ marginBottom: 25 }}>
        {/* Upper section: contains the user's name and university */}
        <div style={{ display: "flex", alignItems: "center" }}>
          <div style={{ display: "flex", alignItems: "center", flex: 1 }}>
            <ProfilePicture university={user.university} />
            <p
              style={{
                margin: 0,
                fontSize: 12,
                fontWeight: "bold",
                userSelect: "none",
              }}
            >
              {user.displayName}
            </p>
          </div>
          <p
            style={{
              margin: 0,
              marginLeft: 5,
              fontSize: 12,
              color: "gray",
              userSelect: "none",
            }}
          >
            {/* For example, 5 hours ago - Implement a proper date/time format */}
            5h ago
          </p>
        </div>

        {/* Middle section: contains the post */}
        <div>
          <p
            style={{
              fontSize: 16,
              fontWeight: "600",
              margin: 0,
              marginTop: 10,
              color: "black",
              userSelect: "none",
            }}
          >
            {post.title}
          </p>
          <p
            ref={contentRef}
            style={{
              color: "#4a4a4a",
              fontSize: 13.8,
              marginBottom: 0,
              marginTop: 7,
              whiteSpace: "pre-wrap",
              display: "-webkit-box",
              WebkitBoxOrient: "vertical",
              overflow: "hidden",
              WebkitLineClamp: isExpanded ? "unset" : maxLines,
              cursor: "pointer",
              fontFamily: "sans-serif",
              userSelect: "none",
              WebkitTapHighlightColor: "transparent",
            }}
            // onClick={() => setTheCurrentPage("EachPost")}
          >
            {post.text}
          </p>
          {/* {!isExpanded && isTruncated && (
            <p
              style={{
                color: "#004AAD",
                fontWeight: "bold",
                cursor: "pointer",
                fontSize: 12,
                marginTop: 5,
                userSelect: "none",
              }}
              onClick={handleToggleExpand}
            >
              ... Show more
            </p>
          )} */}
        </div>

        {/* Lower section: contains the number of likes and comments */}
        <div style={{ marginTop: 5, display: "flex" }}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              userSelect: "none",
            }}
          >
            <IoHeartOutline size={20} color={"black"} />
            <p
              style={{
                margin: 0,
                fontSize: 12,
                fontWeight: "400",
                marginLeft: 2,
                color: "black",
                userSelect: "none",
              }}
            >
              {heartQty}
            </p>
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              marginLeft: 10,
            }}
          >
            <IoChatboxOutline size={20} color={"black"} />
            <p
              style={{
                margin: 0,
                fontSize: 12,
                fontWeight: "400",
                marginLeft: 2,
                color: "black",
                userSelect: "none",
              }}
            >
              {commentQty}
            </p>
          </div>
        </div>
      </div>
    );
  };

  const PostSomething = () => {
    return (
      <div
        style={{ marginBottom: 5, cursor: "pointer" }}
        onClick={() => {
          setOpenPostSomething(true);
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            flex: 1,
          }}
        >
          {console.log(university)}
          {university === "I prefer not to disclose" ? (
            <FaUserCircle size={30} style={{ marginRight: 5 }} color="gray" />
          ) : (
            <img
              style={{ height: 30, marginRight: 5 }}
              src={require(`../assets/universities/${university}.png`)}
            />
          )}

          <label
            style={{
              fontSize: 13,
              margin: 0,
              width: "100%",
              // fontWeight: "",
              border: "1px solid #cfcfcf",
              backgroundColor: "white",
              color: "gray",
              padding: "15px",
              borderRadius: 20,
              userSelect: "none", // This line prevents text selection
            }}
          >
            Any thoughts for today?
          </label>
        </div>
      </div>
    );
  };

  const Sorter = () => {
    const [currentSort, setCurrentSort] = useState("Best");
    const [showModal, setShowModal] = useState(false);

    const sortingOptions = ["Best", "Hot", "New", "Top", "Rising"];

    const handleSortSelect = (option) => {
      setCurrentSort(option);
      setShowModal(false);
    };

    // Inline styles object
    const sortingStyle = {
      sorterButton: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: "#f2f2f2",
        color: "black",
        fontWeight: "500",
        padding: 5,
        border: "none",
        borderRadius: "4px",
        cursor: "pointer",
        fontSize: 13,
        marginBottom: 10,
        outline: "none",
        boxShadow: "none",
        userSelect: "none",
        WebkitTapHighlightColor: "transparent",
      },
      modalOverlay: {
        position: "fixed",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: "rgba(0, 0, 0, 0.5)",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      },
      modalContent: {
        backgroundColor: "white",
        padding: "20px",
        borderRadius: "8px",
        textAlign: "center",
        width: "300px",
      },
      sortOption: {
        backgroundColor: "white",
        border: "none",
        padding: "10px",
        width: "100%",
        textAlign: "left",
        cursor: "pointer",
        fontSize: "16px",
      },
      sortOptionHover: {
        backgroundColor: "white",
      },
      sortOptionSelected: {
        fontWeight: "bold",
        color: "#0079d3",
      },
      closeModal: {
        marginTop: "10px",
        padding: "8px 12px",
        backgroundColor: "#0079d3",
        color: "white",
        border: "none",
        borderRadius: "4px",
        cursor: "pointer",
      },
    };

    return (
      <div>
        {/* Sorter Button */}
        <button
          onClick={() => setShowModal(!showModal)}
          style={sortingStyle.sorterButton}
        >
          {currentSort}{" "}
          <RiArrowDropDownLine style={{ marginLeft: 5 }} size={20} />
        </button>

        {/* Modal for Sorting Options */}
        {showModal && (
          <div style={sortingStyle.modalOverlay}>
            <div style={sortingStyle.modalContent}>
              <h3>Sort by</h3>
              <ul
                style={{
                  listStyle: "none",
                  padding: 0,
                  backgroundColor: "white",
                }}
              >
                {sortingOptions.map((option) => (
                  <li key={option}>
                    <button
                      onClick={() => handleSortSelect(option)}
                      style={{
                        ...sortingStyle.sortOption,
                        ...(option === currentSort
                          ? sortingStyle.sortOptionSelected
                          : {}),
                        ...(option !== currentSort
                          ? sortingStyle.sortOptionHover
                          : {}),
                      }}
                    >
                      {option}
                    </button>
                  </li>
                ))}
              </ul>
              <button
                onClick={() => setShowModal(false)}
                style={sortingStyle.closeModal}
              >
                Close
              </button>
            </div>
          </div>
        )}
      </div>
    );
  };

  const BorderBottom = () => {
    return (
      <div
        style={{
          borderBottom: "2px solid #ccc", // Adjust color and thickness as needed
          margin: "20px 0", // Adds vertical space (gap) between the components
        }}
      />
    );
  };

  const LoadingScreen = () => {
    return (
      <div
        style={{
          position: "fixed",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          backgroundColor: "black",
          opacity: 0.7,
          zIndex: 9999,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <ReactLoading type="spin" color="#FF66C4" height={40} width={40} />
      </div>
    );
  };

  async function uploadPost() {
    setIsLoading(true);
    const postsCollection = collection(db, "Posts");
    const postRef = await addDoc(postsCollection, {
      user: {
        userID: user.uid,
        displayName: displayName,
        university: university,
      },
      post: { title: postTitle, text: postText },
      timestamp: firestoreTimestamp(),
      heartQty: 0,
      commentQty: 0,
    });
    setIsLoading(false);
    setOpenPostSomething(false);
  }

  useEffect(() => {
    // Automatically focus the textarea when the component mounts
    if (openPostSomething) {
      if (postTitleRef.current) {
        postTitleRef.current.focus();
      }
    }
  }, [openPostSomething]);

  useEffect(() => {
    const fetchPosts = async () => {
      const postsCollection = collection(db, "Posts");
      const postsQuery = query(postsCollection, orderBy("timestamp", "desc"));
      const querySnapshot = await getDocs(postsQuery);
      const posts = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setPostDATA(posts);
    };

    fetchPosts();
  }, []);

  return (
    <>
      {isLoading ? (
        <LoadingScreen />
      ) : (
        <>
          {isMobile ? (
            // mobile layout
            <div
              style={{
                padding: 20,
                backgroundColor: "#fffcfc",
                // continue here, add darkmode conditional rendering
                // NOVEMBER 28
              }}
            >
              <div
                style={{
                  maxWidth: 800,
                }}
              >
                {isReminderModalOpen ? (
                  <div
                    style={{
                      position: "fixed",
                      top: 0,
                      left: 0,
                      width: "100%",
                      height: "100%",
                      backgroundColor: "rgba(0, 0, 0, 0.5)", // semi-transparent background
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      zIndex: 1000,
                    }}
                  >
                    <div
                      style={{
                        marginLeft: 30,
                        marginRight: 30,
                        backgroundColor: "white",
                        padding: "20px",
                        borderRadius: "10px",
                        boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
                      }}
                    >
                      <p
                        style={{
                          fontSize: 15,
                          fontWeight: "bold",
                          textAlign: "center",
                          margin: 0,
                          marginBottom: 20,
                        }}
                      >
                        Reminder
                      </p>
                      <p
                        style={{
                          fontSize: 11,
                          // textAlign: "center",
                          margin: 0,
                          marginBottom: 10,
                        }}
                      >
                        Your profile is stored in a temporary state. Once the
                        site is refreshed, any future notifications tied to the
                        current profile will be lost.
                      </p>
                      <p style={{ fontSize: 11, margin: 0, marginBottom: 10 }}>
                        You can navigate through the Main Menu without losing
                        your profile by pressing the back button
                      </p>
                      <p style={{ fontSize: 11, margin: 0 }}>Have fun!</p>
                      <div
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <button
                          onClick={() => {
                            setIsReminderModalOpen(false);
                          }}
                          style={{
                            paddingLeft: 20,
                            paddingRight: 20,
                            border: "none",
                            background: "none",
                            backgroundColor: "#ff66c4",
                            color: "white",
                            fontSize: 12,
                            fontWeight: "bold",
                            borderRadius: 10,
                          }}
                        >
                          <p>Close</p>
                        </button>
                      </div>
                    </div>
                  </div>
                ) : null}
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    backgroundColor: "white",
                    position: "fixed",
                    top: 0,
                    left: 0,
                    right: 0,
                    zIndex: 100, // Ensures it appears above other content
                    padding: "10px", // Optional for spacing
                    boxShadow: "0 2px 5px rgba(0, 0, 0, 0.1)",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <img
                      src={require("../mainlogo.png")}
                      alt="Logo"
                      style={{
                        borderRadius: "100%",
                        height: "25px",
                        marginRight: "5px",
                      }}
                    />

                    <p
                      style={{
                        marginBottom: 0,
                        fontSize: 15,
                        marginTop: 0,
                        color: "#004AAD",
                        fontWeight: "bold",
                      }}
                    >
                      DailyThoughts
                    </p>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    {/* Moon Button - Dark Mode */}
                    {isDarkMode ? (
                      <button
                        style={{
                          display: "flex",
                          background: "none",
                          border: "none",
                          padding: 0,
                          margin: 0,
                          width: "auto",
                          height: "auto",
                          appearance: "none",
                          cursor: "pointer",
                          marginRight: 5,
                        }}
                        onClick={() => setIsDarkMode(false)} // Switch to light mode
                      >
                        <IoSunnyOutline size={25} color={"black"} />
                      </button>
                    ) : (
                      // Sun Button - Light Mode
                      <button
                        style={{
                          display: "flex",
                          background: "none",
                          border: "none",
                          padding: 0,
                          margin: 0,
                          width: "auto",
                          height: "auto",
                          appearance: "none",
                          cursor: "pointer",
                          marginRight: 5,
                        }}
                        onClick={() => setIsDarkMode(true)} // Switch to dark mode
                      >
                        <IoMoonOutline size={25} color={"black"} />
                      </button>
                    )}

                    {/* Notification Button */}
                    <button
                      style={{
                        display: "flex",
                        background: "none",
                        border: "none",
                        padding: 0,
                        margin: 0,
                        width: "auto",
                        height: "auto",
                        appearance: "none",
                        cursor: "pointer",
                      }}
                    >
                      <IoNotificationsOutline size={25} color={"black"} />
                      <p
                        style={{
                          position: "absolute",
                          right: 11,
                          backgroundColor: "#ff66c4",
                          borderRadius: 100,
                          margin: 0,
                          paddingLeft: 3,
                          paddingRight: 3,
                          paddingTop: 1.5,
                          paddingBottom: 1.5,
                          fontSize: 11,
                          fontWeight: "bolder",
                          color: "white",
                        }}
                      >
                        1
                      </p>
                    </button>
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    marginBottom: 5,
                    marginTop: 30,
                  }}
                >
                  <p style={{ fontSize: 9, marginRight: 5, fontWeight: "300" }}>
                    Interacting as
                  </p>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <ProfilePicture university={university} />
                    <p style={{ margin: 0, fontSize: 12, fontWeight: "bold" }}>
                      {displayName}
                    </p>
                  </div>
                </div>

                {openPostSomething ? (
                  <div
                    style={{
                      paddingTop: 30,
                      display: "flex",
                      flexDirection: "column",
                      height: "90vh",
                      backgroundColor: "#fffcfc",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        backgroundColor: "#fffcfc",
                        marginTop: 10,
                      }}
                    >
                      <div
                        style={{ display: "flex", flex: 1 }}
                        onClick={() => {
                          setOpenPostSomething(false);
                        }}
                      >
                        <IoMdClose size={25} />
                      </div>

                      <button
                        style={{
                          display: "flex",
                          justifyContent: "end",
                          border: "none",
                          background: "none",
                        }}
                        onClick={() => {
                          uploadPost();
                        }}
                      >
                        <p
                          style={{
                            margin: 0,
                            padding: 5,
                            backgroundColor: "#ff66c4",
                            color: "white",
                            border: "none",
                            borderRadius: 10,
                            padding: "10px",
                            fontSize: 11,
                            fontWeight: "bold",
                            cursor: "pointer",
                          }}
                        >
                          Share
                        </p>
                      </button>
                    </div>
                    <input
                      ref={postTitleRef}
                      onChange={(e) => {
                        const inputValue = e.target.value;
                        setPostTitle(inputValue);
                      }}
                      style={{
                        marginTop: 10,
                        border: "none",
                        fontSize: 16,
                        fontWeight: "600",
                        outline: "none",
                        color: "black",
                        backgroundColor: "#fffcfc",
                      }}
                      placeholder="Title"
                    />
                    <textarea
                      maxLength={3000}
                      ref={postMessageRef}
                      placeholder="Say something about it"
                      onChange={(e) => {
                        const inputValue = e.target.value;
                        setPostText(inputValue);
                      }}
                      value={postText}
                      style={{
                        marginTop: 5,
                        minHeight: 500,
                        fontSize: 14,
                        paddingBottom: 10,
                        fontFamily: "inherit",
                        fontWeight: "inherit",
                        lineHeight: "inherit",
                        backgroundColor: "#fffcfc",
                        border: "none",
                        outline: "none",
                        overFlowY: "auto", // Hide the scrollbar
                      }}
                    />
                  </div>
                ) : (
                  <div
                    style={
                      {
                        // marginTop: 40,
                      }
                    }
                  >
                    {/* mobile */}
                    <PostSomething />
                    <div style={{ marginBottom: 10 }}>
                      <p style={{ fontSize: 10, color: "black" }}>
                        Please be mindful of what you post here. While your
                        private chats are protected by end-to-end encryption,
                        the content you share here is visible to everyone and
                        monitored for adherence to our community guidelines.
                        Let's keep this space positive and respectful!
                      </p>
                    </div>
                    <Sorter />
                    {postDATA.map((post) => (
                      <EachPost
                        key={post.id}
                        user={post.user} // Passing the entire user object
                        post={post.post}
                        heartQty={post.heartQty}
                        commentQty={post.commentQty}
                      />
                    ))}
                  </div>
                )}
              </div>
            </div>
          ) : (
            // desktop/laptop layout
            <div
              style={{
                display: "flex",
                width: "100%",
                backgroundColor: "white",
              }}
            >
              <div
                style={{
                  width: "35%",
                  margin: "0 auto",
                  display: "flex",
                  flexDirection: "column",
                  height: "100vh",
                  justifyContent: "space-between",
                  boxShadow:
                    "5px 0 15px rgba(0, 0, 0, 0.1), -5px 0 15px rgba(0, 0, 0, 0.1)", // Shadows on left and right sides
                }}
              >
                <div
                  style={{
                    flex: 1,
                    overflowY: "scroll",
                    paddingTop: "20px",
                    paddingRight: "10px",
                    paddingLeft: "10px",
                  }}
                >
                  <Navbar nextPage={"Selection"} />
                  <div style={{ marginTop: 50 }}>
                    {/* desktop */}
                    <PostSomething />
                    <div>
                      <p style={{ fontSize: 12, color: "gray" }}>
                        Please be mindful of what you post here. While your
                        private chats are protected by end-to-end encryption,
                        the content you share here is visible to everyone and
                        monitored for adherence to our community guidelines.
                        Let's keep this space positive and respectful!
                      </p>
                    </div>
                    <BorderBottom />
                    {postDATA.map((post) => (
                      <EachPost
                        key={post.id}
                        university={post.user.university}
                        name={post.user.displayName}
                        post={post.post}
                        heartQty={post.heartQty}
                        commentQty={post.commentQty}
                      />
                    ))}
                  </div>
                </div>
              </div>
            </div>
          )}
        </>
      )}
    </>
  );
};

export default DailyThoughts;
