import React from "react";
import { FaArrowLeft } from "react-icons/fa";
import { UserAuth } from "../context/AuthContext";
import { useNavigate } from "react-router-dom";

const Navbar = ({ nextPage }) => {
  const { setTheCurrentPage, currentPage } = UserAuth();
  const navigate = useNavigate();

  return (
    <div
      style={{
        position: "fixed",
        top: 0,
        left: 0,
        width: "100%",
        backgroundColor: "white",
        padding: "10px 20px",
        boxShadow: "0px 2px 5px rgba(0, 0, 0, 0.2)",
      }}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
        }}
      >
        {currentPage === "MainMenu" ? null : (
          <FaArrowLeft
            style={{ marginRight: 10, cursor: "pointer" }}
            onClick={() => {
              if (currentPage === "DailyThoughts") {
                setTheCurrentPage("Selection");
                navigate("/", { replace: true });
              } else {
                setTheCurrentPage(nextPage);
              }
            }}
          />
        )}

        {currentPage === "MainMenu" ? (
          <div style={{ display: "flex", alignItems: "center" }}>
            <img
              src={require("../mainlogo.png")}
              alt="Logo"
              style={{
                borderRadius: "100%",
                height: "25px",
                marginRight: "5px",
              }}
            />

            <p
              style={{
                marginBottom: 0,
                fontSize: 15,
                marginTop: 0,
                color: "#004AAD",
                fontWeight: "bold",
              }}
            >
              ChatKOOL
            </p>
          </div>
        ) : currentPage === "Selection" ? (
          <div style={{ display: "flex", alignItems: "center" }}>
            <img
              src={require("../mainlogo.png")}
              alt="Logo"
              style={{
                borderRadius: "100%",
                height: "25px",
                marginRight: "5px",
              }}
            />

            <p
              style={{
                marginBottom: 0,
                fontSize: 15,
                marginTop: 0,
                color: "#004AAD",
                fontWeight: "bold",
              }}
            >
              ChatKOOL
            </p>
          </div>
        ) : currentPage === "DailyThoughts" ? (
          <div style={{ display: "flex" }}>
            <div style={{ display: "flex" }}>
              <img
                src={require("../mainlogo.png")}
                alt="Logo"
                style={{
                  borderRadius: "100%",
                  height: "25px",
                  marginRight: "5px",
                }}
              />
              <div>
                <p
                  style={{
                    marginBottom: 0,
                    fontSize: 8,
                    marginTop: 0,
                    color: "#004AAD",
                    fontWeight: "bold",
                  }}
                >
                  ChatKOOL's
                </p>
                <p
                  style={{
                    color: "black",
                    fontSize: 15,
                    marginTop: 0,
                    marginBottom: 0,
                  }}
                >
                  <strong>DailyThoughts</strong>
                </p>
              </div>
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default Navbar;
