import React from "react";
import { FaUserCircle } from "react-icons/fa";

const ProfilePicture = ({ university }) => {
  return !university || university === "I prefer not to disclose" ? (
    <FaUserCircle size={20} style={{ marginRight: 5 }} color="gray" />
  ) : (
    <img
      style={{ height: 25, marginRight: 5 }}
      src={require(`../assets/universities/${university}.png`)}
      alt={`${university} logo`}
    />
  );
};

export default ProfilePicture;
