import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { UserAuth } from "../context/AuthContext";
import { db, rtdb } from "../firebase";
import { arrayUnion, doc, updateDoc } from "firebase/firestore";
import { ref, set } from "firebase/database";
import Select from "react-select";
import ReactLoading from "react-loading";
import GoBack from "../components/GoBack";
import Navbar from "../components/Navbar";
import Modal from "react-modal";

const Selection = () => {
  const {
    anonymousSignIn,
    user,
    displayName,
    hasSetIsTyping,
    university,
    hasSuggested,
    setTheCurrentPage,
    setDisplayName,
    setUniversity,
    setHasSetIsTyping,
    setHasSuggested,
  } = UserAuth();
  const navigate = useNavigate();
  const [isVerifierModalOpen, setIsVerifierModalOpen] = useState(false);
  const [botDetected, setBotDetected] = useState(false);

  // async function continueWithRecaptcha() {
  //   const functions = getFunctions();
  //   const loginFunction = httpsCallable(functions, "login");

  //   try {
  //     setIsVerifierModalOpen(true);
  //     const recaptchaToken = await executeReCaptcha("login");
  //     let data = {
  //       token: recaptchaToken,
  //     };
  //     const result = await loginFunction(data);
  //     if (result.data.valid) {
  //       setIsVerifierModalOpen(false);
  //       setTheCurrentPage("ChatSession");
  //     } else {
  //       setBotDetected(true);
  //     }
  //   } catch (error) {
  //     setIsVerifierModalOpen(false);
  //     setTheCurrentPage("ChatSession");
  //     console.log(error.message);
  //   }
  // }

  useEffect(() => {
    const onBeforeUnload = (e) => {
      e.preventDefault();
      e.returnValue = "";
    };

    window.addEventListener("beforeunload", onBeforeUnload);
    return () => {
      window.removeEventListener("beforeunload", onBeforeUnload);
    };
  }, []);

  return (
    <div className="select-rooms">
      <Modal
        ariaHideApp={false}
        isOpen={isVerifierModalOpen}
        style={{
          content: {
            top: "50%",
            left: "50%",
            right: "auto",
            bottom: "auto",
            maxWidth: 275,
            marginRight: "-50%",
            transform: "translate(-50%, -50%)",
            borderRadius: 10,
          },
          overlay: {
            backgroundColor: "rgba(0, 0, 0, 0.7)",
          },
        }}
      >
        {botDetected ? (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <p style={{ fontSize: 15, fontWeight: "bold" }}>Bot detected</p>
            <p style={{ fontSize: 11 }}>We have detected that you are a bot</p>
            <p style={{ fontSize: 11, marginTop: 10 }}>
              To access chatkool again, refresh the site
            </p>
          </div>
        ) : (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <ReactLoading
              color={"#FF66C4"}
              type={"spin"}
              height={35}
              width={35}
            />
            <p style={{ fontSize: 11, fontWeight: "400" }}>
              Let me verify if you are a human, please wait
            </p>
          </div>
        )}
      </Modal>
      <Navbar nextPage="MainMenu" />
      {/* DAILY THOUGHTS */}
      {/* <div style={{ maxWidth: 350, marginBottom: 40, marginTop: 50 }}>
        <p
          style={{
            fontWeight: "bold",
            marginBottom: 0,
            color: "#ff66c4",
            fontSize: 13,
          }}
        >
          NEW!
        </p>
        <p
          style={{
            color: "#000000",
            fontSize: 20,
            marginBottom: 0,
            marginTop: 0,
          }}
        >
          <strong>DailyThoughts</strong>
        </p>
        <p style={{ fontSize: 13, marginTop: 10 }}>
          Share a piece of your day! Whether it's an idea, story, question, or
          just what's on your mind, express it here and see what others have
          shared too!
        </p>
        <div style={{ display: "flex", marginTop: 10, maxWidth: 350 }}>
          <button
            onClick={() => {
              navigate("/dailythoughts");
            }}
            disabled={!user}
            style={{
              flex: 1,
              backgroundColor: user ? "#ff66c4" : "#ccc",
              color: "white",
              border: "none",
              borderRadius: "5px",
              height: "40px",
              cursor: user ? "pointer" : "not-allowed",
            }}
          >
            <h4 style={{ margin: 0, padding: 0 }}>Go</h4>
          </button>
        </div>
      </div> */}
      {/* CHAT WITH A STRANGER */}
      <div style={{ maxWidth: 350, marginBottom: 40, marginTop: 50 }}>
        <p style={{ color: "#000000", fontSize: 20, marginBottom: 0 }}>
          <strong>Chat with a Stranger</strong>
        </p>
        <div>
          <p style={{ fontSize: 13, marginTop: 10 }}>
            Connect anonymously with fellow college students across the
            Philippines. Share experiences and support each other while keeping
            your identity private for a safe and enriching chat experience!
          </p>
        </div>

        <div style={{ display: "flex", marginTop: 10, maxWidth: 350 }}>
          <button
            onClick={async () => {
              // await continueWithRecaptcha();
              setTheCurrentPage("ChatSession");
            }}
            disabled={!user}
            style={{
              flex: 1,
              backgroundColor: user ? "#ff66c4" : "#ccc",
              color: "white",
              border: "none",
              borderRadius: "5px",
              height: "40px",
              cursor: user ? "pointer" : "not-allowed",
            }}
          >
            <h4 style={{ margin: 0, padding: 0 }}>Connect</h4>
          </button>
        </div>
      </div>
    </div>
  );
};

export default Selection;
